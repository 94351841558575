import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d0ddf76")
const _hoisted_1 = { class: "page--limited card" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "p-d-flex p-ai-center" }
const _hoisted_7 = { class: "p-formgroup" }
const _hoisted_8 = { class: "p-d-flex" }
const _hoisted_9 = {
  key: 1,
  class: "p-formgroup"
}
const _hoisted_10 = { class: "p-field p-fluid" }
const _hoisted_11 = { class: "p-field p-fluid" }
const _hoisted_12 = { class: "p-field p-fluid" }
const _hoisted_13 = { class: "p-field p-fluid" }
const _hoisted_14 = { class: "p-field p-fluid" }
const _hoisted_15 = { class: "price-container" }
const _hoisted_16 = { class: "p-formgroup" }
const _hoisted_17 = { class: "p-field p-fluid" }
const _hoisted_18 = { class: "p-field p-fluid" }
const _hoisted_19 = { class: "p-field p-fluid" }
const _hoisted_20 = { class: "p-field p-fluid" }
const _hoisted_21 = { class: "p-mr-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_CompanyAutocomplete = _resolveComponent("CompanyAutocomplete")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Fieldset = _resolveComponent("Fieldset")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: _ctx.backRoute }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-arrow-left",
              class: "p-mb-0"
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('back'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isNew)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$t('contract.title_new')), 1))
          : (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.$t('contract.title_edit')), 1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(_component_Button, {
          icon: "pi pi-save",
          class: "p-mr-1",
          onClick: _ctx.onSave
        }, null, 8, ["onClick"]), [
          [
            _directive_tooltip,
            _ctx.$t('save'),
            void 0,
            { bottom: true }
          ]
        ]),
        _withDirectives(_createVNode(_component_Button, {
          icon: "pi pi-trash",
          class: "p-button-danger",
          onClick: _ctx.onDelete
        }, null, 8, ["onClick"]), [
          [
            _directive_tooltip,
            _ctx.$t('delete'),
            void 0,
            { bottom: true }
          ]
        ])
      ])
    ]),
    (_ctx.requestPending && !_ctx.contract)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
              return _createElementVNode("div", {
                class: "p-field p-fluid",
                key: `skeleton_${i}`
              }, [
                _createVNode(_component_Skeleton, {
                  height: "2rem",
                  class: "p-mb-2"
                })
              ])
            }), 64))
          ]),
          _createVNode(_component_Skeleton, {
            height: "5rem",
            class: "p-mb-2"
          }),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Skeleton, {
              height: "2rem",
              width: "20rem",
              class: "p-mr-2"
            }),
            _createVNode(_component_Skeleton, {
              height: "2rem",
              width: "6rem"
            })
          ])
        ], 64))
      : (_ctx.contract)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_FloatLabel, {
                label: _ctx.$t('contract.name')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.contract.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.contract.name = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_CompanyAutocomplete, {
                modelValue: _ctx.contract.client,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.contract.client = $event)),
                type: 200,
                label: "Client"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_FloatLabel, {
                label: _ctx.$t('contract.distance_dz_on')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.contract.distance_dz_on,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.contract.distance_dz_on = $event)),
                    locale: "en-US"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_FloatLabel, {
                label: _ctx.$t('contract.distance_dz_off')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.contract.distance_dz_off,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.contract.distance_dz_off = $event)),
                    locale: "en-US"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_FloatLabel, {
                label: _ctx.$t('contract.notes')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.contract.note,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.contract.note = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.price_fieldset, (fieldset, index) => {
                return (_openBlock(), _createBlock(_component_Fieldset, {
                  class: "p-mb-4",
                  key: fieldset.key
                }, {
                  legend: _withCtx(() => [
                    _createTextVNode(_toDisplayString(fieldset.legend), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("section", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_FloatLabel, {
                          label: _ctx.$t('contract.price_hour')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InputNumber, {
                              tabindex: index + 1,
                              onFocus: ($event: any) => (_ctx.calculateFee('price_hour_', fieldset.key)),
                              modelValue: _ctx.contract['price_hour_' + fieldset.key],
                              "onUpdate:modelValue": ($event: any) => (_ctx.contract['price_hour_' + fieldset.key] = $event),
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US"
                            }, null, 8, ["tabindex", "onFocus", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_FloatLabel, {
                          label: _ctx.$t('contract.price_cycle')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InputNumber, {
                              tabindex: index + 6,
                              onFocus: ($event: any) => (_ctx.calculateFee('price_cycle_', fieldset.key)),
                              modelValue: _ctx.contract['price_cycle_' + fieldset.key],
                              "onUpdate:modelValue": ($event: any) => (_ctx.contract['price_cycle_' + fieldset.key] = $event),
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US"
                            }, null, 8, ["tabindex", "onFocus", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_FloatLabel, {
                          label: _ctx.$t('contract.price_ton')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InputNumber, {
                              tabindex: index + 11,
                              onFocus: ($event: any) => (_ctx.calculateFee('price_ton_', fieldset.key)),
                              modelValue: _ctx.contract['price_ton_' + fieldset.key],
                              "onUpdate:modelValue": ($event: any) => (_ctx.contract['price_ton_' + fieldset.key] = $event),
                              mode: "currency",
                              currency: "USD",
                              locale: "en-US"
                            }, null, 8, ["tabindex", "onFocus", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_20, [
              _withDirectives(_createElementVNode("label", _hoisted_21, null, 512), [
                [_directive_t, 'contract.min_hours_cancellation']
              ]),
              _createVNode(_component_ToggleButton, {
                modelValue: _ctx.contract.min_hours_late_canc,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.contract.min_hours_late_canc = $event)),
                onLabel: "Yes",
                offLabel: "No",
                onIcon: "pi pi-check",
                offIcon: "pi pi-times",
                style: {"width":"6em"}
              }, null, 8, ["modelValue"])
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}