import { Contract } from "@/model/api/Contract";
import { contractsService } from "@services/contracts.service";
import { Options, Prop, Vue } from "vue-property-decorator";
import { ContractsRoutesEnum } from "../../router";
import {CompanyAutocomplete} from "@components";
import {removeNullValues} from "@/utils/utils";


@Options({
  components: {
    CompanyAutocomplete
  }
})
export default class ContractPage extends Vue {
  @Prop() readonly contractId!: string;

  contract: Contract = null;

  price_fieldset = [
    {
      legend: 'Client prices',
      key: 'client'
    },
    {
      legend: 'Customer fee',
      key: 'customer'
    },
    {
      legend: 'Broker fee',
      key: 'broker'
    },
    {
      legend: 'Driver fee',
      key: 'driver'
    },
    {
      legend: 'System fee',
      key: 'system'
    }
  ]

  get isNew(){
    return this.contractId === 'new';
  }

  get backRoute() {
    return { name: ContractsRoutesEnum.CONTRACTS_LIST }
  }

  onSave() {

    if(this.contract.client){
      this.contract.client_id = this.contract.client.id
    }

    removeNullValues(this.contract)

    let fail: boolean

    const sum = {
      price_hour: {
        amount: 0,
        label: 'Hour'
      },
      price_cycle: {
        amount: 0,
        label: 'Cycle'
      },
      price_ton: {
        amount: 0,
        label: 'Ton'
      },
    }
    this.price_fieldset.forEach((fieldset) => {
      if(fieldset.key !== 'client'){
        sum.price_hour.amount += this.contract['price_hour_' + fieldset.key]
        sum.price_cycle.amount += this.contract['price_cycle_' + fieldset.key]
        sum.price_ton.amount += this.contract['price_ton_' + fieldset.key]
      }
    })

    for (const property in sum) {
      if(sum[property].amount !== this.contract[property + '_client']){
        this.$errorMessage(sum[property].label +  " price not congruent with fees");
        fail = true
      }
    }

    if(fail){
      return
    }

    this.$waitFor(
      async () => {
        if (this.isNew) {
          const response = await contractsService.create(this.contract);

          this.$router.replace({
            name: ContractsRoutesEnum.CONTRACTS_DETAIL,
            params: { contractId: response.id }
          }).then(() =>
            this.$successMessage(this.$t(`contract.messages.create_success`))
          );
        } else {
          const response = await contractsService.updatePatch(this.contract);
          this.$successMessage(this.$t(`contract.messages.update_success`));
        }

      },
      this.$t('Operation failed')
    );
  }

  calculateFee(field, key){
    if(key === 'system'){
      if(!this.contract[field + 'system'] || this.contract[field + 'system'] === 0){
        this.contract[field + 'system'] = this.contract[field + 'client'] - (this.contract[field + 'customer'] + this.contract[field + 'broker'] + this.contract[field + 'driver'])
      }
    }

  }

  onDelete(){
    this.$confirmMessage("Are you sure to delete this Contract?")
    .then( r => {
      if (r) {
        this.deleteContract();
      }
    })
  }

  private deleteContract(){
    this.$waitFor(
      async () => {
        await contractsService.deleteBySystem(this.contract);

        this.$router.replace({ name: ContractsRoutesEnum.CONTRACTS_LIST })
          .then( () => 
            this.$successMessage("Contract successfully deleted") 
          );
      },

      "Deleting Contract failed"
    )
  }


  private _loadContract() {
    return this.$waitFor(
      async () => {
        this.contract = await contractsService.getById(+this.contractId);
      },
      "Contract not found"
    )
  }

  async created() {
    if (this.isNew) {
      this.contract = new Contract();
    } else {
      await this._loadContract();
    }
  }

}