import { BaseApiModel } from "@/model/common/BaseApiModel";

export class Contract extends BaseApiModel {
    name: string;

    price_cycle_client: number;
    price_hour_client: number;
    price_ton_client: number;

    price_cycle_broker: number;
    price_hour_broker: number;
    price_ton_broker: number;

    price_cycle_driver: number;
    price_hour_driver: number;
    price_ton_driver: number;

    price_cycle_customer: number;
    price_hour_customer: number;
    price_ton_customer: number;

    price_cycle_system: number;
    price_hour_system: number;
    price_ton_system: number;

    note: string;

    client: any;
    client_id: number;

    min_hours_late_canc : boolean;
    distance_dz_on      : number;
    distance_dz_off     : number;
}